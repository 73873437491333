/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import 'normalize.css';
import './layout.css';
import './hamburger.css';

import * as React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { createGlobalStyle } from 'styled-components';
import fontFiles from '../fonts/fonts';
import theme from '../styles/theme';
import FreshChat from 'react-freshchat';

type Props = {
  children: React.ReactNode;
};

const styleKeys = Object.keys(fontFiles);

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: "ProximaNovaRegular";
    font-style: normal;
    font-weight: 400;
    src: local("ProximaNovaRegular"), url(${fontFiles.ProximaNovaRegular}) format("truetype");
  }
  @font-face {
    font-family: "ProximaNovaSemiBold";
    font-style: normal;
    font-weight: 600;
    src: local("ProximaNovaSemiBold"), url(${fontFiles.ProximaNovaSemiBold}) format("truetype");
  }

  @font-face {
    font-family: "ProximaNovaExtraBold";
    font-style: normal;
    font-weight: 800;
    src: local("ProximaNovaExtraBold"), url(${fontFiles.ProximaNovaExtraBold}) format("truetype");
  }
`;

const Layout: React.FC<Props> = ({ children, path }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isBrowser = () => typeof window !== 'undefined';

  // Hubspot tracking code manual call (since our website is a single page application)
  //https://community.hubspot.com/t5/Reporting-Analytics/Setting-the-single-page-app-SPA-option-in-hubspot-tracking-code/td-p/214907
  React.useEffect(() => {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div id="mainContainer">
        <main>{children}</main>
      </div>

      {isBrowser() && (
        <FreshChat token={'a53ef754-a5db-4b97-9b4a-eb32bc10a57d'} />
      )}
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
