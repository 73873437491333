import GilroyBlack from './gilroy/Gilroy-Black.ttf';
import GilroyBold from './gilroy/Gilroy-Bold.ttf';
import GilroySemiBold from './gilroy/Gilroy-SemiBold.ttf';

import ProximaNovaRegular from './proxima-nova/ProximaNova-Regular.ttf';
import ProximaNovaExtraBold from './proxima-nova/ProximaNova-Extrabld.ttf';
import ProximaNovaSemiBold from './proxima-nova/ProximaNova-Semibold.ttf';

const fonts = {
  GilroyBlack,
  GilroySemiBold,
  GilroyBold,
  ProximaNovaRegular,
  ProximaNovaExtraBold,
  ProximaNovaSemiBold,
};

// https://github.com/gatsbyjs/gatsby/issues/2583
// https://github.com/LekoArts/gatsby-source-tmdb-example/blob/master/src/fonts/inter-ui.css
export default fonts;
