export const colors = {
  primary: '#3897f0',
  lightprimary: '#FFFFFF',
  oldlightsecondary: '#F8F7F5',
  lightsecondary: '#FFFFFF',
  backgroundalternate: '#FAFAFA',
  carouselLight: '#F5F5F5',
  light2: '#D1D1D1',
  light3: '#F2F1EE',
  black: '#282828',
  dark1: '#333333',
  dark2: '#494949',
  dark3: '#D8D8D8',
  dark4: '#DEDEDE',
  dark5: '#201F1F',
  lightorange: '#F39B84',
  darkorange: '#CE9B6C',
  redish: '#F53349',
  yellow: '#FFCC00',
  green: '#9CB839',
  blue: '#6191B4',
  greenish: '#B8B26A',
  salmon: '#F39B84',
  olivegreen: '#66704F',
};

const dimensions = {
  headerLogo: 24,
  headerMenu: 32,
  headerWrapperMaxWidth: 1200,
  gutterSize: 15,
  borderRadius: 8,
  lineHeightSmall: 18,
  marginTopH3: 96,
  marginTopH3Small: 64,
  buttonLetterSpacing: 0.5,
  sectionSpacing: 192,
};

const textSizes = {
  covidBanner: 18,
  smallText: 12,
  iconListItem: 14,
  extraLargeText: 64,
  headerMenu: 16,
  headerMenuLarge: 20,
  headerTitleSize: 48,
  headerTitleTablet: 32,
  section: 30,
  sectionTitle: 40,
  sectionLong: 40,
  sectionSmall: 24,
  sectionSubtitle: 20,
  mobile: {
    covidBanner: 16,
    iconListItem: 12,
    headerMenu: 12,
    headerMenuLarge: 16,
    headerTitleSize: 36,
    headerTitleTablet: 24,
    extraLargeText: 56,
    sectionSubtitle: 16,
  },
};

const lineHeights = {
  headerMenu: 24,
  iconListItem: 20,
  sectionSubtitle: 32,
  headerTitleTablet: 40,
  extraLargeText: 96,
  mobile: {
    headerMenu: 18,
    iconListItem: 18,
    headerTitleTablet: 32,
    extraLargeText: 56,
    sectionSubtitle: 24,
  },
};

export const breakpoints = {
  handheld: 510,
  tablet: 670,
  desktop: 992,
  large: 1200,
  extralarge: 1400,
  gigantic: 1600,
  // Sliders hack
  unlimited: 8000,
};

export const colorTheme = {
  colors,
  dimensions,
  textSizes,
  lineHeights,
};

export default colorTheme;
